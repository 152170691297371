<template>
  <div class="flex flex-col gap-4">
    <h1>Cash in omloop</h1>

    <p class="italic">
      Een overzicht van cash ontvangsten die nog in omloop zijn en nog niet afgerekend zijn, per chauffeur.
    </p>

    <UITabs class-button="block" class="flex w-full" :tabs="tabs" v-model="tab" />
    <div class="flex flex-row gap-2">
      <div>
        <label for="year">Year</label>
        <UISelectMinMax
          min="2018"
          :max="new Date().getFullYear()"
          v-model="filters.year"
          id="year"
        />
      </div>
    </div>

    <div v-for="(items, index) in Object.values(filteredItems)" :key="index">
      <h2 class="mb-2 mt-4">{{ index === 0 ? 'Eigen chauffeurs' : 'Onderaannemers' }}</h2>
      <UITableResponsive :items="items" :headers="headers" class="text-center" :suffix-headers="['Acties']">
        <template v-slot:item-Chauffeur="{ item }">
          <span class="block -mb-0.5">{{ store.getters.chauffeur(item.user_id)?.label || '?' }}</span>
          <small class="block italic text-gray-500 font-bold pl-2 -mb-1">&euro;&nbsp;{{ formatPrice(item.totaal) }}</small>
        </template>
        <template v-slot:item-JAN="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_1) }}
        </template>
        <template v-slot:item-FEB="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_2) }}
        </template>
        <template v-slot:item-MAART="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_3) }}
        </template>
        <template v-slot:item-APR="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_4) }}
        </template>
        <template v-slot:item-MEI="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_5) }}
        </template>
        <template v-slot:item-JUNI="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_6) }}
        </template>
        <template v-slot:item-JUL="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_7) }}
        </template>
        <template v-slot:item-AUG="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_8) }}
        </template>
        <template v-slot:item-SEP="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_9) }}
        </template>
        <template v-slot:item-OCT="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_10) }}
        </template>
        <template v-slot:item-NOV="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_11) }}
        </template>
        <template v-slot:item-DEC="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal_12) }}
        </template>
        <template v-slot:item-Acties="{ item }">
          <router-link v-if="item.user_id > 0" :to="`/administratie/afrekenen?chauffeur=${item.user_id}`" class="mr-2 btn info small">
            <i class="fas fa-book-reader"></i>
            Naar afrekening
          </router-link>
        </template>
      </UITableResponsive>
    </div>

  </div>
</template>

<script setup>
import { ref, inject, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { formatPrice } from '@/functions/formatNumber'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UITabs from '@/components/UI/Tabs.vue'

const tabs = ['Actieve gebruikers', 'Inactieve gebruikers']
const tab = ref(0)

const store = useStore()
const axios = inject('axios')

const list = ref([])
const count = ref(0)

const filters = ref({
  year: new Date().getFullYear(),
  limit: 20,
  offset: 0,
})

const headers = [
  'Chauffeur',
  'JAN',
  'FEB',
  'MAART',
  'APR',
  'MEI',
  'JUNI',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
]

onMounted(() => {
  watch(filters, getData, { deep: true })
  getData()
})

const getData = async () => {
  const { data } = await axios.get('/api/dashboard/afrekenen/cash-in-omloop', { params: filters.value })
  if (data.list) list.value = data.list
  if (data.count) count.value = data.count
  return data
}


const filteredItems = computed(() => {
  const records = list.value.filter(item => store.getters.chauffeur(item.user_id)?.is_active == (tab.value === 0))

  return {
    chauffeurs: records.filter(item => store.getters.chauffeur(item.user_id)?.type !== 'onderaannemer'),
    onderaannemers: records.filter(item => store.getters.chauffeur(item.user_id)?.type === 'onderaannemer'),
  }
})
</script>
